import * as React from "react"
import EnginuityGcLink from "../components/enginuity_gc_link"

export const homeCards = [
  {
    title: "Design",
    content: <span> Planning is the foundation for all successful projects, construction
      or otherwise. At Enginuity Consulting Engineers, LLC, we employ the latest construction
      design technology using AutoCAD and REVIT to take our Client's design to the next level.</span>
  },
  {
    title: "Construction",
    content: <span> Our sister company, <EnginuityGcLink/> is a Certified General Contractor in
      the State of Florida. We can provide a true Design-Build product to our clients. By providing Design Services
      "In-House", we are better able to control costs vs our competition. </span>
  },
  {
    title: "Management",
    content: <span>Our team can manage your project, whether commercial or industrial, from start to finish.
      With over 40 years of commercial and industrial project management experience, no project is too big.</span>
  }
]
