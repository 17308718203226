import * as React from "react"
import Layout from "../components/layout"
import {
  description,
  cardTitle,
  cardContent,
  divider,
  services,
  emphatic,
  servicesTitle,
  bannerOverlay,
  bannerOverlayLower,
  bannerIdent,
  bannerIdentLower
} from "../css/index.module.css"
import {homeCards} from "../data/home_cards"
import {specialities} from "../data/specialities"
import {SEO} from "../components/seo"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BrandedDivider from "../components/branded_divider"
import BannerImage from "../components/banner_image"
import banner from "../images/home.jpg"
import lowerBanner from "../images/engineering.jpg"

const IndexPage = () => {

  return (
    <Layout pageTitle="Home" noHeader={true}>
      <BannerImage image={banner}>
        <div className={bannerOverlay}><></>
        </div>
        <div className={bannerIdent}>
          Bringing Creativity To The Design Table
        </div>
      </BannerImage>

      <div className={description}>
        <Container className="py-5">
          <Row>
            <p>
              Enginuity Consulting Engineers, LLC is a Tampa, FL based Engineering Firm concentrating on Mechanical,
              Electrical, Plumbing
              and Fire Protection.
            </p>
            <p>
              We are a Veteran Owned Business.
            </p>
            <div className="d-flex justify-content-center mt-3">
              <div className={divider}/>
            </div>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="gx-lg-5 my-4">
          {homeCards.map(card => {
            return (
              <Col key={card.title} className="my-4" lg={true}>
                <h5 className={cardTitle}>{card.title}</h5>
                <div className={cardContent}>{card.content}</div>
              </Col>
            )
          })}
        </Row>
      </Container>

      <BannerImage image={lowerBanner}>
        <div className={bannerOverlayLower}><></>
        </div>
        <div className={bannerIdentLower}>
          Turning Dreams Into Reality
        </div>
      </BannerImage>

      <div className={services}>
        <Container className="py-5">
          <h5 className={servicesTitle}>Over 500 Projects Completed</h5>
          <div className="mb-4">
            <small>specialising in</small>
          </div>
          {specialities.map(specialty => {
            return (
              <span key={specialty}>{specialty}<BrandedDivider/></span>
            )
          })}
        </Container>
      </div>
    </Layout>
  )
}

export const Head = () => <SEO/>

export default IndexPage

