import * as React from "react"

const EnginuityGcLink = () => {
  return (
    <a href="https://www.enginuitygc.com" target="_blank">
      Enginuity Construction, LLC (CGC057601)
    </a>
  )
}

export default EnginuityGcLink
